import { Component, Input, HostListener, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { ColumnType, IgxGridComponent, IgxTooltipTargetDirective, IgxTooltipDirective } from '@infragistics/igniteui-angular';
import { GridMethodsService } from 'src/app/services/grid-methods.service';
import { NgClass } from '@angular/common';

@Component({
    selector: 'grid-filter-input',
    templateUrl: './grid-filter-input.component.html',
    styleUrls: ['./grid-filter-input.component.scss'],
    standalone: true,
    imports: [NgClass, IgxTooltipTargetDirective, IgxTooltipDirective]
})
export class GridFilterInputComponent implements OnInit {
  constructor(public gms: GridMethodsService) { }
  showClearButton = false;
  @Input() grid!: IgxGridComponent;
  @Input() column!: ColumnType;
  @ViewChild('input') inputElement!: ElementRef<HTMLInputElement>

  @HostListener('click') onClick() {
    this.inputElement.nativeElement.focus()
  }

  ngOnInit(): void {
    this.setClearButton();
  }

  setClearButton() {
    if (this.column.index !== 0) return
    this.grid.filteringExpressionsTreeChange.subscribe(() => {
      const totalFilters = this.grid.filteringExpressionsTree.filteringOperands.length;
      this.showClearButton = totalFilters > 0;
    });
  }

  clearButtonClick() {
    this.showClearButton = false;
    this.gms.clearAllFilters(this.grid);
  }

  private lastKeyPressTime = 0;
  private doubleTapThreshold = 300;

  keydownHandler(event: KeyboardEvent) {
    if (event.code === 'Escape') {

      this.inputElement.nativeElement.value = "";
      this.gms.onInput(this.grid, this.inputElement.nativeElement, this.column);

      var currentTime = new Date().getTime();
      if (currentTime - this.lastKeyPressTime < this.doubleTapThreshold) {
        this.gms.clearAllFilters(this.grid);
      }
      this.lastKeyPressTime = currentTime;
      return
    }

  }


}
