<input #input tabindex="0" class="" [ngClass]="showClearButton ? 'filterInput-with-btn' : 'filterInput'"
  autocomplete="off" style="text-align: left;" [value]="gms.getFilterValue(column)"
  (input)="gms.onInput(grid, input, column)" (keyup)="keydownHandler($event)" (click)="gms.onClick($event)"
  (keydown)="gms.onKeyDown($event)" autocomplete="nope" />

@if (input.value) {
<span class="material-symbols-outlined grid-filter-reset-button"
  (click)="input.value = ''; gms.onInput(grid, input, column)">close</span>
}
@if (showClearButton ) {
<span class="material-symbols-outlined grid-filter-clear-button" (click)="clearButtonClick()" #target="tooltipTarget"
  [igxTooltipTarget]="tooltipRef">filter_alt_off</span>
<div class="tooltip" #tooltipRef="tooltip" igxTooltip>
  Klikk her for å blanke ut alle filter kriterier.
</div>
}