import { Injectable } from '@angular/core';
import { IgxGridComponent, GridColumnDataType, IgxNumberFilteringOperand, IgxStringFilteringOperand, ColumnType, IgxDateFilteringOperand } from '@infragistics/igniteui-angular';

@Injectable({
  providedIn: 'root'
})
export class GridMethodsService {

  constructor() { }

  private _filterValues = new Map<ColumnType, any>();

  public formatDate(val: Date) {
    return new Intl.DateTimeFormat('en-US').format(val);
  }

  public formatCurrency(val: string) {
    return parseInt(val, 10).toFixed(2);
  }

  public getFilterValue(column: ColumnType): any {
    return this._filterValues.has(column) ? this._filterValues.get(column) : null;
  }

  public onKeyDown(event: KeyboardEvent) {
    event.stopImmediatePropagation();
  }

  public onInput(grid: IgxGridComponent, input: HTMLInputElement, column: ColumnType) {


    this._filterValues.set(column, input.value);

    if (input.value === '') {
      grid.clearFilter(column.field);
      return;
    }

    let operand = null;
    switch (column.dataType) {
      case GridColumnDataType.Number:
        operand = IgxNumberFilteringOperand.instance().condition('equals');
        break;
      default:
        operand = IgxStringFilteringOperand.instance().condition('contains');
    }
    grid.filter(column.field, this.transformValue(input.value, column), operand, column.filteringIgnoreCase);
  }

  public clearInput(grid: IgxGridComponent, column: ColumnType) {
    this._filterValues.delete(column);
    grid.clearFilter(column.field);
  }

  public onClick(inputGroup: any) {
    if (!inputGroup.isFocused) {
      inputGroup.input.focus();
    }
  }

  public onDateSelected(grid: IgxGridComponent, event: any, column: ColumnType) {
    if (!event) {
      this.clearInput(grid, column);
      return;
    }

    this._filterValues.set(column, event);
    grid.filter(column.field, event, IgxDateFilteringOperand.instance().condition('equals'),
      column.filteringIgnoreCase);
  }

  private transformValue(value: any, column: ColumnType): any {
    if (column.dataType === GridColumnDataType.Number) {
      value = parseFloat(value);
    }

    return value;
  }

  public clearAllFilters(grid: IgxGridComponent) {
    this._filterValues.clear();
    grid.clearFilter();
  }
}

/*
private _filterValues = new Map<IgxColumnComponent, any>();

onRightClick(event: any) {
  event.preventDefault();
}
public formatDate(val: Date) {
  return new Intl.DateTimeFormat('en-US').format(val);
}

public formatCurrency(val: string) {
  return parseInt(val, 10).toFixed(2);
}

public getFilterValue(column: IgxColumnComponent): any {
  return this._filterValues.has(column) ? this._filterValues.get(column) : null;
}

public onKeyDown(event: KeyboardEvent) {
  event.stopImmediatePropagation();
}

public onInput(grid: IgxGridComponent, input: any, column: IgxColumnComponent) {
  this._filterValues.set(column, input.value);

  if (input.value === '') {
    grid.clearFilter(column.field);
    return;
  }

  let operand = null;
  switch (column.dataType) {
    case GridColumnDataType.Number:
      operand = IgxNumberFilteringOperand.instance().condition('equals');
      break;
    default:
      operand = IgxStringFilteringOperand.instance().condition('contains');
  }
  grid.filter(column.field, this.transformValue(input.value, column), operand, column.filteringIgnoreCase);
}


public onClick(event: any) {
  const { target } = event;
  if (!target.isFocused) {
    target.focus();

  }
}


public openDatePicker(openDialog: () => void) {
  openDialog();
}

private transformValue(value: any, column: IgxColumnComponent): any {
  if (column.dataType === GridColumnDataType.Number) {
    value = parseFloat(value);
  }

  return value;
}
} */
